<template>
  <section id="app">
    <header class="d-flex justify-content-between">
      <!-- redes -->
      <div class="p-2">
        <!-- Whatsapp -->
        <a
          aria-label="Icono de Whatsapp"
          href="https://api.whatsapp.com/message/DFXAEG33VKIZH1?autoload=1&app_absent=0"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light"
          role="button"
          ><i class="fa-brands fa-whatsapp"></i
        ><p class="d-none d-inline comment">Whatsapp</p></a>

        <!-- Instagram -->
        <a
          aria-label="Icono de Instagram"
          href="https://www.instagram.com/orbita.com.co/"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          role="button"
          ><i class="fa-brands fa-instagram"></i
        ><p class="d-none d-inline comment">Instagram</p></a>

        <!-- Linkedin -->
        <a
          aria-label="Icono de Linkedin"
          href="https://www.linkedin.com/company/iorbita/"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          role="button"
          ><i class="fa-brands fa-linkedin"></i
        ><p class="d-none d-inline comment">Linkedin</p></a>

        <!-- youtube -->
        <a
          aria-label="Icono de youtube"
          href="https://youtube.com/@DesarrollandoElFuturo?si=0gOr3DwEAEQqwqqy"
          target="_blank"
          rel="noopener"
          class="btn btn-outline-light btn-floating border border-light mx-1"
          role="button"
        >
          <i class="fa-brands fa-youtube"></i
        ><p class="d-none d-inline comment">youtube</p></a>
      </div>

      <!-- logo -->
      <div class="">
        <!-- escritorio -->
        <img
          v-if="activeComponent.name != 'OrbitaInicio'"
          class="logo-orbita d-none d-sm-none d-md-block"
          src="https://static.orbita.com.co/public/general/images/Logo_Amarillo_Orbita.png"
          alt="logo orbita amarillo"
        />
        <!-- Movil -->
        <img
          v-if="activeComponent.name != 'OrbitaInicio'"
          class="logo-orbita d-block d-sm-block d-md-none"
          src="https://static.orbita.com.co/public/general/images/Icono_Amarillo_Orbita.png"
          alt="logo orbita amarillo"
        />
      </div>
    </header>

    <main>
      <!-- contenido -->
      <div class="card-general">
        <Transition name="fade" mode="out-in">
          <component :is="activeComponent"></component>
        </Transition>
      </div>

      <!-- Navegacion escritorio-->
      <div class="d-flex justify-content-center pt-lg-2">
        <div class="row justify-content-center">
          <button
            id="btnInicio"
            aria-label="Inicio"
            class="btn btn-warning rounded-lg col-6 col-sm-3 col-lg-2 texto"
            :class="{ active: activeComponent === OrbitaInicio }"
            @click="activeComponent = OrbitaInicio"
          >
            <span class="d-none d-md-inline">Inicio</span>
            <i class="d-md-none bi bi-house"></i>
            
          </button>

          <button
            taria-label="¿Quienes Somos?"
            id="btnQuieness"
            class="btn btn-warning rounded-lg col-6 col-sm-3 col-md-2 col-lg-2 texto"
            :class="{ active: activeComponent === OrbitaQuieness }"
            @click="activeComponent = OrbitaQuieness"
          >
            <span class="d-none d-md-inline texto">¿Quienes Somos?</span>
            <i class="d-md-none bi bi-file-earmark-person"></i>            
          </button>

          <button
          aria-label="Servicios"
            id="btnServicios"
            class="btn btn-warning rounded-lg col-6 col-sm-3 col-md-2 col-lg-2 texto"
            :class="{ active: activeComponent === OrbitaServicios }"
            @click="activeComponent = OrbitaServicios"
          >
            <span class="d-none d-md-inline">Servicios</span>
            <i class="d-md-none bi bi-card-checklist"></i>
            
          </button>

          <button
          aria-label="Planes"
            id="btnPlanes"
            class="btn btn-warning rounded-lg col-6 col-sm-3 col-md-2 col-lg-4 texto"
            :class="{ active: activeComponent === OrbitaPlanes }"
            @click="activeComponent = OrbitaPlanes"
          >
            <span class="d-none d-md-inline">Planes</span>
            <i class="d-md-none bi bi-cart"></i>
            
          </button>

          <button
          aria-label="Equipo"
            id="btnEquipo"
            class="btn btn-warning rounded-lg col-6 col-sm-3 col-md-2 col-lg-4 texto"
            :class="{ active: activeComponent === OrbitaEquipo }"
            @click="activeComponent = OrbitaEquipo"
          >
            <span class="d-none d-md-inline">Equipo</span>
            <i class="d-md-none bi bi-person-heart"></i>
            
          </button>

          <button
            aria-label="Contactanos"
            id="btnContactanos"
            class="btn btn-warning rounded-lg  m-0 p-0 col-6 col-sm-3 col-md-2 col-lg-2 texto"
            :class="{ active: activeComponent === OrbitaContactanos }"
            @click="activeComponent = OrbitaContactanos"
          >
            <span class="d-none d-md-inline m-0 p-0">Contactanos</span>
            <i class="d-md-none bi bi-phone"></i>
          </button>
        </div>
      </div>
    </main>

    <footer>
      <div class="d-flex justify-content-center align-content-center">
        <p class="d-flex justify-content-center pt-2">
          ©2020 al 2024 Copyright <a href="#" class="pt-0 mt-0 p-1"> www.orbita.com.co</a>
        </p>
      </div>
    </footer>
  </section>
</template>

<script setup>
import { shallowRef } from "vue";
import OrbitaInicio from "@/views/OrbitaInicio.vue";
import OrbitaEquipo from "@/views/OrbitaEquipo.vue";
import OrbitaContactanos from "@/views/OrbitaContactanos.vue";
import OrbitaQuieness from "@/views/OrbitaQuieness.vue";
import OrbitaServicios from "@/views/OrbitaServicios.vue";
import OrbitaPlanes from "@/views/OrbitaPlanes.vue";

const activeComponent = shallowRef(OrbitaInicio);
</script>

<script>
export default {
  data() {
    return {
      activeComponent: null,
      OrbitaInicio,
    };
  },
};
</script>

<style scoped>
.btn-warning {
  height: 60px;
  width: 130px;
  color: #19013f;
  font-weight: bold;
  background-color: #fcfafa;
  margin: 2px;
}

.card-general {
  height: calc(88vh - 90px);
  background-color: rgba(0, 0, 0, 0.28);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #8f09c8;
  color: #fcfafa;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
}

.card-general::-webkit-scrollbar {
  display: none;
}

.logo-orbita {
  height: 60px;
  position: relative;
  top: 35px;
  left: -35px;
}

button {
  transition: transform 0.9s;
}

button.active {
  transform: translateY(-15px);
}


/* Móvil */
@media screen and (max-width: 624px) and (max-width: 624px) {
  a {
    border: none !important;
    border-radius: -46px;
    margin: 3px;
  }

  i {
    font-size: 20px;
  }

  .btn-warning {
    height: 50px;
    width: 100px;
  }

  /* Botones */
  .btn-warning {
    border-radius: 46px !important;
    width: 47px;
    height: 50px;
  }
}

@media screen and (min-width: 625px) {
  .card-general {
    height: calc(80vh - 20px);
  }
}

@media  (min-width: 600px) and (max-width: 991px) {
  .btn-warning {
    height: 60px;
    width: 69px;
  }

 .texto{
    font-size: 11px;
 }
}

@media (min-width: 600px) and (max-width: 1280px) {

  a {
    border: none !important;
    border-radius: -46px;
    margin: -5px;
  }
}

/* Tablet */
@media screen and (min-width: 630px) and (max-width: 1279px) {
}

/* Portátil */
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  a {
    border: none !important;
  }
}

/* Escritorio */
@media screen and (min-width: 1920px) {
  a {
    border: none !important;
    border-radius: -46px;
    margin: 5px;
  }

  i {
    font-size: 20px;
  }
}
</style>
