<template>
  <section class="d-flex flex-column p-3 py-4">
    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- primera card  -->
      <div class="col-lg-4 card p-3">        
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_admin_empresarial.png"
              alt="Administracion empresarial" />
          </div>
            <div>
              <h1 class="title-services">Administración Empresarial</h1>
              <h2 class="subtitle-services">
                Optimizamos procesos y decisiones estratégicas.
              </h2>
            </div>
        </div>
      </div>
      <!-- segunda card -->
      <div class="col-lg-4 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img class="" src="https://static.orbita.com.co/public/general/images/icono_marketin_y_diseño.png"
              alt="marketing y diseño" />
          </div>
          <div>
            <h1 class="title-services">Marketing y Diseño</h1>
            <h2 class="subtitle-services">
              Destaca con estrategias digitales y diseño atractivo.
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- tercera card -->
      <div class="col-lg-3 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_nube.png" alt="nube" />
          </div>
          <div>
            <h1 class="title-services">Nube</h1>
            <h2 class="subtitle-services">
              Garantizamos una disponibilidad del 100% para tus datos
              empresariales.
            </h2>
          </div>
        </div>
      </div>
      <!-- cuarta card -->
      <div class="col-lg-3 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_app_medida.png" alt="App a media" />
          </div>
          <div>
            <h1 class="title-services">App a Medida</h1>
            <h2 class="subtitle-services">
              Aplicaciones personalizadas para mejorar tu productividad y
              experiencia del usuario.
            </h2>
          </div>
        </div>
      </div>
      <!-- quinta card -->
      <div class="col-lg-3 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_seguirdad.png" alt="seguridad" />
          </div>
          <div>
            <h1 class="title-services">Seguridad</h1>
            <h2 class="subtitle-services">
              Protegemos tus activos digitales con medidas de seguridad sólidas.
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center align-items-center">
      <!-- sexta carta -->
      <div class="col-lg-4 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_database.png" alt="icono de database" />
          </div>
          <div>
            <h1 class="title-services">Base De Datos</h1>
            <h2 class="subtitle-services">
              Organizamos y gestionamos tu información de manera eficiente.
            </h2>
          </div>
        </div>
      </div>
      <!-- septima card -->
      <div class="col-lg-4 card p-3">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img src="https://static.orbita.com.co/public/general/images/icono_asesoria_y_soporte.png"
              alt="Asesoria y soporte" />
          </div>
          <div>
            <h1 class="title-services">Asesoría y Soporte</h1>
            <h2 class="subtitle-services">
              Resolvemos tus desafíos tecnológicos.
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OrbitaServicios'
}
</script>


<style scoped>
.card {
  border-radius: 15px;
  background: rgba(143, 9, 200, 0.05);
  border: 1px solid #8f09c8;
  border-radius: 33px;
  margin: 10px;
  color: aliceblue;
  width: 370px;
  height: 200px;
}

.title-services {
  color: #ffcc01;
  font-size: 30px;
  font-family: "Uniform Rounded";
}

.subtitle-services {
  color: #ffffff;
  font-family: "TT Hazelnuts Trl";
  font-size: 20px;
}
</style>

