<template>
  <div class="container">
    <div class="row ">
      <div class="App">
        <VistaGeneral />
      </div>
    </div>
  </div>
</template>

<script>

import VistaGeneral from "./components/VistaGeneral.vue";

export default {
  name: "App",
  components: {
    VistaGeneral,
  },
};
</script>

<style>

@import '~bootstrap-icons/font/bootstrap-icons.css';

@font-face {
  font-family: "Uniform Rounded"; /* Nombre de la fuente principal */
  src: url(https://static.orbita.com.co/public/general/fuentes/Uniform/uniformrnd-bold.otf) format("opentype"); /* Ruta relativa a la carpeta de las fuentes y formato del archivo */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TT Hazelnut"; /* Nombre de la fuente secundaria */
  src: url(https://static.orbita.com.co/public/general/fuentes/TT_Hazelnut/TTHazelnuts-Medium-Trial.otf) format("opentype"); /* Ruta relativa a la carpeta de las fuentes y formato del archivo */
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Uniform Rounded", sans-serif;
}

p {
  font-family: "TT Hazelnut", sans-serif;
}

label {
  font-family: "TT Hazelnut", sans-serif;
}

section{ 
  width: 100%; 
  height: 100%;
}

#app {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  flex: none; 
}

main {
  overflow: hidden;
  flex: 1;
  display: grid;
}

footer {
  flex: none; 
  color: azure;
}

body {
  /* overflow: hidden;  */
  background-image: url(https://static.orbita.com.co/public/general/images/Fondo_Orbita.png);
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
