<!-- Quienes Somos-->
<template>
  <section class="d-flex justify-content-lg-center align-items-lg-center">
    <div class="d-flex">
      <div
        class="m-4 d-flex flex-wrap justify-content-center align-items-center"
      >
        <div class="col-lg-10 card">
          <h1 class="title-services">Quienes Somos</h1>
          <p class="m-3">
            El Equipo de Órbita comienza a mediados de 2020 como una agencia de
            desarrollo de software en búsqueda de empresas que requieren
            realizar un propio software a media y necesidad del cliente. Ubicada
            en Colombia (Bogotá D.C) brindando servicios en todo Colombia.
            Queremos que Órbita sea importante para tu empresa, Y por eso tu
            empresa es lo más importante para nosotros. La Confianza es la clave
            para construir relaciones productivas y duraderas, tanto entre
            nosotros como el cliente.
          </p>
        </div>

        <div class="col-lg-5 card">
          <h1 class="title-services">Misión</h1>
          <p class="m-3">
            En Orbita proporcionamos soluciones de software empresarial a la
            medida, con implementación de módulos personalizados por nuestros
            clientes, nos enfocamos en el sector empresarial y ofrecemos un
            servicio de calidad, innovación, personalización y mejoramiento
            continuo de nuestros aplicativos para satisfacer las necesidades
            especificas en el mercado.
          </p>
        </div>

        <div class="col-lg-5 card">
          <h1 class="title-services">Visión</h1>
          <p class="m-3">
            En el 2028, nos convertiremos en líderes a nivel nacional en el
            desarrollo de soluciones de software, que proporcione una estructura
            organizacional, que permita la ejecución y control de los procesos.
            Destacaremos por una constante innovación, enfoque en la seguridad
            informática y un sólido soporte técnico, certificado por las normas
            de calidad Internacional. Nuestro compromiso es impulsar el
            crecimiento y éxito de los clientes, contribuyendo a su
            fortalecimiento y expansión.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OrbitaQuieness",
};
</script>

<style scoped>
.card {
  border-radius: 15px;
  background: rgba(143, 9, 200, 0.05);
  border: 1px solid #8f09c8;
  border-radius: 55px;
  padding: 10px;
  margin: 10px;
  color: aliceblue;
}

.title-services {
  color: #ffcc01;
  font-family: "Uniform Rounded";
  align-items: center;
  text-align: center;
}
</style>
